import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import SupportIcon from '@material-ui/icons/CommentOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HelpIcon from '@material-ui/icons/Help';
import clsx from 'clsx';
// import * as authActions from 'app/auth/store/actions';
import FeedbackDialog from 'app/components/FeedbackDialog';
import { LicenseGroupLineIcon, LogoutIcon, SettingsIcon, ProfileIcon } from 'app/components/Icons';
import useGetPageSection from 'app/hooks/useGetPageSection';
import { useSelector } from 'app/modules/react-redux';
import {
	getManagedLicenseGroups,
	getTenantManagerGroupsById,
	getProfile,
	getRolePermissionCheck,
	getSelectedLicenseGroupId
} from 'app/store/reducers';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { getHelpUrl, getRootDomain } from 'app/utils/helpers';
import getHelpContextUrl from 'app/utils/getHelpContextUrl';

const UserMenu = ({ adminPage = false }: { adminPage: boolean }) => {
	const { t, i18n } = useTranslation();
	const profile = useSelector(getProfile);
	// const adminedLicenseGroups = useSelector(getAdminedLicenseGroups) as
	// 	| ReturnType<typeof getAdminedLicenseGroups>
	// 	| undefined; // HACK::use actual type here - assume never undefined within the wrapped app
	const managedLicenseGroups = useSelector(getManagedLicenseGroups) as
		| ReturnType<typeof getManagedLicenseGroups>
		| undefined; // HACK::use actual type here - assume never undefined within the wrapped app
	const managedGroups = Object.values(useSelector(getTenantManagerGroupsById)) ?? [];
	const selectedLicenseGroupId = useSelector(getSelectedLicenseGroupId) as
		| ReturnType<typeof getSelectedLicenseGroupId>
		| undefined; // HACK::use actual type here - assume never undefined within the wrapped app

	const pageSection = useGetPageSection();
	const rolePermissionCheck = useSelector(getRolePermissionCheck);

	const [userMenu, setUserMenu] = useState<HTMLButtonElement | null>(null);

	const userMenuClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		setUserMenu(event.currentTarget);
	};

	const userMenuClose = () => {
		setUserMenu(null);
	};

	const { pathname } = useLocation();

	const hasManagerPage =
		(managedLicenseGroups && managedLicenseGroups.length > 0) || (managedGroups && managedGroups.length > 0);

	return (
		<>
			<Button
				className={clsx('h-64 px-20 starter:text-white landing-toolbar-text-white')}
				onClick={userMenuClick}
			>
				<Avatar className="text-theme-secondary bg-white">{profile.firstName[0]}</Avatar>

				<div className="flex-col mx-12 items-start">
					<Typography component="span" className="normal-case font-600 flex">
						{t('header:welcome name', { name: profile.firstName })}
					</Typography>
				</div>

				<ExpandMoreIcon className={clsx('transition-transform transform', { 'rotate-x-180': !!userMenu })} />
			</Button>

			<Popover
				keepMounted
				open={!!userMenu}
				anchorEl={userMenu}
				onClose={userMenuClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center'
				}}
				classes={{
					paper:
						'py-8 starter:text-white landing-toolbar-text-white starter:bg-theme-secondary-900 rounded-none rounded-b-8'
				}}
			>
				{/* {(pageSection.admin || pageSection.publicProfile) && (
					<MenuItem component={Link} to="/welcome" onClick={userMenuClose} role="button">
						<ListItemIcon className="min-w-40 starter:text-white landing-toolbar-text-white">
							<Icon>storefront</Icon>
						</ListItemIcon>
						<ListItemText primary={t('user menu:home')} />
					</MenuItem>
				)} */}

				{/* {!pageSection.admin && adminedLicenseGroups && adminedLicenseGroups.length > 0 && (
					<MenuItem component={Link} to="/" onClick={userMenuClose} role="button">
						<ListItemIcon className="min-w-40 starter:text-white landing-toolbar-text-white">
							<LicenseGroupLineIcon />
						</ListItemIcon>
						<ListItemText primary={t('user menu:admin')} />
					</MenuItem>
				)} */}

				{!pageSection.manager &&
					hasManagerPage &&
					// checking if I'm not in a subdomain to decide if I can just push to page
					(window.location.host === getRootDomain() ? (
						<MenuItem component={Link} to="/manager/tenants" onClick={userMenuClose} role="button">
							<ListItemIcon className="min-w-40 starter:text-white landing-toolbar-text-white">
								<LicenseGroupLineIcon />
							</ListItemIcon>
							<ListItemText primary={t('user menu:tenant manager')} />
						</MenuItem>
					) : (
						<a href={`${window.location.protocol}//${getRootDomain()}/manager/tenants`} role="button">
							<MenuItem>
								<ListItemIcon className="min-w-40 starter:text-white landing-toolbar-text-white">
									<LicenseGroupLineIcon />
								</ListItemIcon>
								<ListItemText primary={t('user menu:tenant manager')} />
							</MenuItem>
						</a>
					))}

				{/* <ComingSoonWrapper>
					<MenuItem component={Link} to="/about" onClick={userMenuClose} role="button">
						<ListItemIcon className="min-w-40 starter:text-white landing-toolbar-text-white">
							<InfoIcon />
						</ListItemIcon>
						<ListItemText primary={t('user menu:about')} />
					</MenuItem>
				</ComingSoonWrapper> */}

				{pageSection.licenseGroupDomain ? (
					<MenuItem component={Link} to="/admin/profile" onClick={userMenuClose} role="button">
						<ListItemIcon className="min-w-40 starter:text-white landing-toolbar-text-white">
							<ProfileIcon />
						</ListItemIcon>
						<ListItemText primary={t('user menu:admin profile')} />
					</MenuItem>
				) : (
					<MenuItem component={Link} to="/profile" onClick={userMenuClose} role="button">
						<ListItemIcon className="min-w-40 starter:text-white landing-toolbar-text-white">
							<ProfileIcon />
						</ListItemIcon>
						<ListItemText primary={t('user menu:public profile')} />
					</MenuItem>
				)}
				<FeedbackDialog>
					<MenuItem onClick={userMenuClose} role="button">
						<ListItemIcon className="min-w-40 starter:text-white landing-toolbar-text-white">
							<SupportIcon />
						</ListItemIcon>
						<ListItemText primary={t('user menu:feedback')} />
					</MenuItem>
				</FeedbackDialog>

				<a
					href={getHelpUrl(i18n.language, getHelpContextUrl(pathname))}
					target="_blank"
					rel="noreferrer noopener"
					role="button"
				>
					<MenuItem>
						<ListItemIcon className="min-w-40 starter:text-white landing-toolbar-text-white">
							<HelpIcon />
						</ListItemIcon>
						<ListItemText primary={t('Help')} />
					</MenuItem>
				</a>

				{pageSection.admin &&
					selectedLicenseGroupId &&
					rolePermissionCheck('settingsTab.settingsSection.view') && (
						<MenuItem component={Link} to="/admin/settings" onClick={userMenuClose} role="button">
							<ListItemIcon className="min-w-40 starter:text-white landing-toolbar-text-white">
								<SettingsIcon />
							</ListItemIcon>
							<ListItemText primary={t('Settings')} />
						</MenuItem>
					)}

				<a href="/api/sso/logout" role="button">
					<MenuItem>
						<ListItemIcon className="min-w-40 starter:text-white landing-toolbar-text-white">
							<LogoutIcon />
						</ListItemIcon>
						<ListItemText primary={t('Logout')} />
					</MenuItem>
				</a>
			</Popover>
		</>
	);
};

export default UserMenu;
